import React, { useRef, useState, useEffect } from "react";
import { Checkbox, FormControlLabel, Grid, Modal } from '@mui/material';
import { Box } from "@mui/material";
import Titulo from "../../../components/Titulo";
import styled from "styled-components";
import CampoLeitura from "../../../components/CampoLeitura";
import autenticaStore from "../../../stores/autentica.store";
import http from "../../../http";
import { GridRowId } from "@mui/x-data-grid";
import IClifor from "../../../types/IClifor";
import IVenda from "../../../types/IVenda";
import IPagamento from "../../../types/IPagamento";
import { formataMoeda, transformDateBR } from "../../../customFunctions/mascarasFormatacao";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { NumericFormat } from 'react-number-format';
import Botao from "../../../components/Botao";
import Select from 'react-select';
import IUser from "../../../types/IUser";
import IBodyRequest from "../../../types/IBodyRequest";
import IColumn from "../../../types/IColumn";
import ICard from "../../../types/ICard";

const BoxCustomizado = styled(Box)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw; 
    max-height: 90vh;
    overflow-y: auto;
    background-color: var(--cinza);
    border: none;
    border-radius: 5px;
    padding: 1em 1em;

    @media (max-width: 768px) {
        width: 95vw; 
    }

    @media (max-width: 1060px) {
        width: 90vw; 
    }
`;

const SpanErro = styled.span`
    float: left;
    color: #bdbdbd;
    font-size: 14px;
    margin-top: -25px;
`

const Campo = styled.input`
    color:rgb(255, 255, 255);
    font-size: 15px;
    background:rgba(240, 240, 240, 0.08);
    margin-top: 3px;
    margin-bottom: 0px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
`

const Rotulo = styled.label`
    display: block;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--cinza-claro)
`

const InputMaskCustomizado = styled(InputMask)`
    color:rgb(255, 255, 255);
    font-size: 15px;
    background:rgba(240, 240, 240, 0.08);
    margin-top: 3px;
    margin-bottom: 0px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
`

const CampoTextoLongo = styled.textarea`
    color:rgb(255, 255, 255);
    background:rgba(240, 240, 240, 0.08);
    width: 100%;
    padding: 8px;
    margin-top: 3px;
    border-radius: 4px;  
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    min-height: 100px; /* Ajuste a altura mínima conforme necessário */
`;

const BotaoCustomizado = styled(Botao)`
    width: 50%;
    display: block;
    margin: 0 auto;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    cursor: pointer;    
`

const BotaoCustom = styled.button`
  font-size: 14px;
  font-weight: 500;
  background-color: #0000d2;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
`;

const SelectCustomizado = styled(Select)`
 margin-top: 3px;
`;

const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background: 'rgba(240, 240, 240, 0.08)',
      color: 'white',
      border: 'none',
      boxShadow: '2px 2px 6px rgba(0, 0, 0, 0.25)',
      borderRadius: '3px',
    }),
    menu: (provided: any) => ({
      ...provided,
      background: 'rgb(109, 109, 109)',
      color: 'white',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? 'rgba(255, 255, 255, 0.1)' : 'rgba(240, 240, 240, 0.08)',
      color: 'white',
      '&:hover': {
        background: 'rgba(240, 240, 240, 0.08)',
      },
    }),
    singleValue: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    input: (provided: any) => ({
      ...provided,
      color: 'white',
    }),
    placeholder: (provided: any) => ({
        ...provided,
        color: 'rgb(202, 202, 202)', // Ajuste a cor do placeholder aqui
    }),
  };

interface Anexo {
    id: string;
    fileName: string;
    createdAt: string;
  }

export default function ModalCRM({ open, handleClose, dados}: { open: boolean, handleClose: () => void, dados: ICard}) {
    const {register, handleSubmit, reset, formState:{errors}, setValue, control, getValues, trigger, watch} = useForm({ mode: 'onSubmit'});
    const [selectedState, setSelectedState] = useState<{ value: string } | null>(null);
    const [index, setIndex] = useState(0);    
    const [indexAnexo, setIndexAnexo] = useState(0);    
    const [meiosPagamento, setMeiosPagamento] = React.useState([{ meiopagamento: '', tipopagamento: '' }]);
    const [anexo, setAnexo] = React.useState([{ id: '', fileName: '', createdAt: '' }]);
    const [anexos, setAnexos] = useState<Anexo[]>([]);
    const taxasComissao = useRef([{"taxa": 0, "comissao": 0, "vlrbase": 0, "meioPag": {value: '', label: ''}, "tipoPag": {value: '', label: ''}, "bandeira": {value: '', label: ''}}]);
    const {usuario} = autenticaStore;
    const [colaborador, setColaborador] = useState("");
    const [colaboradores, setColaboradores] = useState<IUser[]>([]); 
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [atendente, setAtendente] = useState("");
    const [papel, setPapel] = useState<string | null>('')
    const [colabNome, setColabNome] = useState("")
    const [cardID, setCardID] = useState("")
    const [lastUpdate, setLastUpdate] = useState("")
    const [preencheu, setPreencheu] = useState(false);
    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };
          

    if(open && !preencheu){

        http.get<IColumn[]>('column', axiosConfig).then(resposta => {
            let colunas = resposta.data.map(column => ({
                value: column.id,
                label: column.name
            }));
            setValue('status', colunas.filter(c => c.value == dados.statusId)[0])
        })
        .catch(erro => {
            alert(erro);
        });
        setValue('atendente', {value: dados.atendenteId, label: dados.atendente})
        setValue('nome', dados.nome)
        setValue('status', dados.statusId)
        setValue('lastUpdate', dados.lastStatusUpdate)
        setValue('cardID', dados.id)
        setValue('status', dados.statusId)
        setValue('email', dados.email)
        setValue('linkedin', dados.linkedin)
        setValue('telefone', dados.telefone)
        setValue('produto', dados.produto)
        setValue('preco', dados.preco)
        setValue('objetivo', dados.objetivo) 
        setValue('descricao', dados.descricao)
    
    }else if(!open && preencheu){
        reset()
        setPreencheu(false)
        
    } 

    /* useEffect(() => {
        if (dados.atendente) {
          setValue('atendente', dados.atendente);
        }
      }, [dados, setValue]); */

     
    
    

    useEffect(() => {
        if(open){
          if(colaboradores.length === 0){
            http.get<IBodyRequest<IUser>>(`usuarios`, axiosConfig)
            .then(resposta => {
                let atend = colaboradores.filter(c => c.login == usuario.login);
                if(atend.length > 0){   
                    console.log("atendente: " + atend[0].nome)
                    setAtendente(atend[0].nome)
                }else{
                    setAtendente("")
                }    
                setColaboradores(resposta.data.content)
            })
            .catch(erro => {
                alert(erro)
            })  
          }
          if(columns.length === 0){
            http.get<IColumn[]>('column', axiosConfig)
                .then(resposta => {
                    const activeColumns = resposta.data.filter((column: IColumn) => column.active);
                    activeColumns.sort((a: IColumn, b: IColumn) => a.position - b.position);
                    setColumns(activeColumns);

                })
                .catch(erro => {
                    alert(erro);
                });
            }
           
          /* if(colaborador === ""){
            
            http.get(`usuarios/${localStorage.getItem('userID')}`, axiosConfig).then(resposta => {
              setPapel(resposta.data.papel)  
              if(resposta.data.papel === 'COLABORADOR'){
                
              let colabDados = colaboradores.filter(c => c.idusuario == localStorage.getItem('userID'))
              if (colabDados.length > 0){ 
                if (colabDados[0].cpfcnpj) {
                  setColaborador(colabDados[0].cpfcnpj);
                  setColabNome(colabDados[0].cpfcnpj + " - " + colabDados[0].nomecomercial + " " + colabDados[0].nomesocial)
                  
                }
              }
              }
            })
            .catch(erro => {
                alert(erro)
            });
          } */
          
        }
        
    
      }, [colaborador, open]);

    const colaboradoresFormatados = colaboradores.map(colaborador => ({
    value: colaborador.id,
    label: colaborador.nome
    }));

    const colunasFormatadas = columns.map(column => ({
        value: column.id,
        label: column.name
        }));

    const addTarefa = () => {
        setMeiosPagamento([...meiosPagamento, { meiopagamento: '', tipopagamento: '' }]);
        setIndex(index + 1)
        taxasComissao.current.push({"taxa": 0, "comissao": 0, "vlrbase": 0, "meioPag": {value: '', label: ''}, "tipoPag": {value: '', label: ''}, "bandeira": {value: '', label: ''}})

    }

    const addAnexo = () => {

        setAnexo([...anexo, { id: '', fileName: '', createdAt: '' }]);
        setIndexAnexo(index + 1)
        taxasComissao.current.push({"taxa": 0, "comissao": 0, "vlrbase": 0, "meioPag": {value: '', label: ''}, "tipoPag": {value: '', label: ''}, "bandeira": {value: '', label: ''}})

    }

    const removeMeioPagamento = (indexToRemove: number) => {
        setValue(`meiopagamento[${indexToRemove}]`, "") 
        setValue(`tipopagamento[${indexToRemove}]`, "")
        setValue(`bandeira[${indexToRemove}]`, "")
        setValue(`parcelas[${indexToRemove}]`, "")
        setValue(`vlrbase[${indexToRemove}]`, "")
        setValue(`datavenda[${indexToRemove}]`, "")
        setValue(`valorcomprador[${indexToRemove}]`, "")
        setValue(`taxaFixa[${indexToRemove}]`, 0)
        setValue(`comissao[${indexToRemove}]`, 0)

        setMeiosPagamento(meiosPagamento.filter((_, index) => index !== indexToRemove));
        setIndex(index - 1)
        taxasComissao.current.splice(indexToRemove, 1)

      };

      const onSubmit = (data: any) => {
        console.log(data);
      };

      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
          const file = e.target.files[0];
          const newAnexo: Anexo = {
            id: `${indexAnexo}`,
            fileName: file.name,
            createdAt: new Date().toISOString(),
          };
          setAnexos([...anexos, newAnexo]);
          setIndexAnexo(indexAnexo + 1);
        }
      };

   
    return (
        <>
            {<Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <BoxCustomizado>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid item xs={12}>
                            <h2 style={{textAlign: "center", color: 'white'}}>Card</h2>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={3}>
                                <Rotulo>Atendente</Rotulo>                                
                                <Controller
                                    name="atendente"
                                    control={control}
                                    render={({ field }) => (
                                        <SelectCustomizado
                                        {...field}
                                        value={field.value}
                                        options={colaboradoresFormatados}
                                        onChange={(data) => {
                                            field.onChange(data);
                                        }}
                                        isSearchable
                                        styles={customStyles}
                                        />
                                    )}
                                    />                                                             
                            </Grid> 
                            <Grid item xs={12} md={3}>
                                <Rotulo>Status</Rotulo>                                
                                <Controller
                                    name="status"
                                    control={control}
                                    //defaultValue={}
                                    render={({ field }) => (
                                        <SelectCustomizado
                                        {...field}
                                        value={field.value}
                                        options={colunasFormatadas}
                                        onChange={(selectedOption) => {
                                            field.onChange(selectedOption);
                                        }}
                                        isSearchable
                                        styles={customStyles}
                                        />
                                    )}
                                />                                                                
                            </Grid> 
                                                        
                            <Grid item xs={12} md={3}>
                                <Rotulo>Ultimo Update</Rotulo>
                                <Campo type="text" readOnly {...register('lastUpdate')} />
                            </Grid> 
                            <Grid item xs={12} md={3}>
                                <Rotulo>ID</Rotulo>
                                <Campo type="text" readOnly {...register('cardID')} />
                            </Grid>

                            <Grid item xs={12} md={6}>                                                                                     
                                <Rotulo>Nome *</Rotulo>                                
                                <Campo type="text" placeholder="" maxLength={100} {...register('nome',{
                                required: 'Campo obrigatório',
                                minLength: {
                                    value: 3,
                                    message: 'Deve ter no mínimo 3 caracteres'
                                },
                                maxLength: {
                                    value: 100,
                                    message: 'Deve ter no máximo 100 caracteres'
                                }
                                })} />
                                 {errors.nome && typeof errors.nome.message === 'string' && <SpanErro>{errors.nome.message}</SpanErro>}                         
                            </Grid>   

                            <Grid item xs={6} md={3}>
                                <Rotulo>E-mail</Rotulo>                                
                                <Campo type="text" placeholder="" maxLength={100} {...register('email', {
                                required: "Email obrigatório",
                                pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: 'Favor digitar um email válido'
                                }})} />
                                {/* {errors.email && typeof errors.email.message === 'string' && <SpanErro>{errors.email.message}</SpanErro>} */}
                            </Grid>    

                            <Grid item xs={6} md={3}>
                                <Rotulo>Telefone</Rotulo>                                
                                <InputMaskCustomizado mask="(99) 99999-9999" maskChar={null} {...register('telefone', {
                                        minLength: {
                                        value: 14,
                                        message: `Favor digitar um telefone válido`
                                        }})} />
                                {/* {errors.telefone && typeof errors.telefone.message === 'string' && <SpanErro>{errors.telefone.message}</SpanErro>} */}
                            </Grid>    

                            <Grid item xs={12} md={6}>
                                <Rotulo>Linkedin</Rotulo>
                                <Campo type="text" placeholder="" maxLength={200} {...register('linkedin')} />
                            </Grid>    

                             

                            <Grid item xs={12} md={3}>
                                <Rotulo>Produto</Rotulo>
                                <Campo type="text" placeholder="" maxLength={200} {...register('produto')} />
                            </Grid>
                            
                            <Grid item xs={12} md={3}>
                                <Rotulo>Preço</Rotulo>                                        
                                <Controller
                                    name={`preco`}
                                    control={control}
                                    rules={{ required: "Campo obrigatório" }}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => {
                                                                                          
                                        /* const indice = index      */                             
                                        return (
                                            <NumericFormat                    
                                                value={value}
                                                prefix={"R$ "}
                                                thousandSeparator={"."}
                                                decimalScale={2}
                                                fixedDecimalScale={true}
                                                allowNegative={false}  
                                                decimalSeparator=","   
                                                allowedDecimalSeparators={[".", ","]}
                                                onValueChange={(values) => onChange(values.floatValue)}     
                                                onBlur={() => { 
                                                    //console.log("BLUR")
                                                    
                                                }}                       
                                                customInput={Campo}                                    
                                            />
                                        );
                                    }}
                                />
                                {errors.vlrbase && typeof errors.vlrbase.message === 'string' && <SpanErro>{errors.vlrbase.message}</SpanErro>}
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <Rotulo>Objetivo</Rotulo>
                                <Campo type="text" placeholder="" maxLength={200} {...register('objetivo')} />
                            </Grid>  

                            <Grid item xs={12} md={12}>
                                <Rotulo>Descrição</Rotulo>
                                <CampoTextoLongo {...register('descricao', { maxLength: 2000 })} />
                                {errors.descricao && typeof errors.descricao.message === 'string' && <SpanErro>{errors.descricao.message}</SpanErro>}
                            </Grid> 
                            
                            <Grid item xs={12} md={5} style={{paddingBottom: "10px", marginLeft: "-108px"}}>
                                <BotaoCustomizado style={{backgroundColor: "green"}} onClick={() => addTarefa()}>Adicionar Tarefa</BotaoCustomizado>  
                            </Grid>  
                            
                            {meiosPagamento.map((meioPagamento, index) => (
                                <React.Fragment key={index}>  
                                    <Grid container spacing={2} style={{borderRadius: "5px", marginBottom: "1px", marginLeft: "2px"}} >
                                        <Grid item xs={12} md={1}>
                                            <FormControlLabel
                                                control={<Checkbox color="warning" style={{color: "var(--cinza-claro)", marginLeft: "0px"}}
                                                onChange={(e) => {
                                                    const isChecked = e.target.checked;
                                                    /* setExtra(isChecked);
                                                    somaTotalComprador() */
                                                    // Execute sua função aqui
                                                    //minhaFuncao(isChecked);
                                                }}/>}
                                                label=""
                                                labelPlacement="end"
                                                style={{marginTop: "-2px"}}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={10}>       
                                                                                                                          
                                                                        
                                            <Campo type="text" style={{marginTop: "0px", marginBottom: "10px", marginLeft: "-50px"}} placeholder="" maxLength={255} {...register(`meiopagamento[${index}]`,{
                                            required: 'Campo obrigatório',
                                            minLength: {
                                                value: 3,
                                                message: 'Deve ter no mínimo 3 caracteres'
                                            },
                                            maxLength: {
                                                value: 100,
                                                message: 'Deve ter no máximo 255 caracteres'
                                            }})} />
                                                                    
                                        </Grid>   
                                                
                                                                
                                        <Grid item xs={2} md={1} >
                                            <BotaoCustomizado style={{backgroundColor: "rgb(197, 0, 0)", marginTop: "5px", marginLeft: "-50px", padding: "0px"}} onClick={() => removeMeioPagamento(index)}>X</BotaoCustomizado>
                                            
                                        </Grid>  
                                    </Grid> 
                                    
                                </React.Fragment>   
                            ))}   

                             

                            <Grid item xs={12} md={6} style={{marginTop: "23px"}}>
                                <Grid item xs={12} md={10} style={{paddingBottom: "10px", marginLeft: "0px", marginBottom: "3px"}}>
                                <BotaoCustom as="label">
                                    Anexar Arquivo
                                    <Campo type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                                </BotaoCustom>
                                </Grid> 
                                {anexo.map((anexo, indexAnexo) => (
                                    <React.Fragment key={indexAnexo}>  
                                        <Grid container spacing={2} style={{borderRadius: "5px", marginBottom: "1px", marginLeft: "2px"}} >

                                            <Grid item xs={11} md={11} >                                                                                     
                                                                            
                                                <Campo type="text" style={{marginTop: "0px", marginBottom: "10px", marginLeft: '-17px'}} placeholder="" readOnly maxLength={255}  {...register(`meiopagamento[${indexAnexo}]`)} />
                                                                        
                                            </Grid>                                                  
                                                                    
                                            
                                        </Grid> 
                                        
                                    </React.Fragment>   
                                ))}  
                            </Grid>                      
                        </Grid>    
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                            </Grid>
                              
                        </Grid>                    
                    </form>
                </BoxCustomizado>
            </Modal >}
        </>
    );
}