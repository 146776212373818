import styled, { createGlobalStyle } from 'styled-components';
import React, { useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import autenticaStore from "../../../stores/autentica.store";
import http from '../../../http';

const GlobalStyle = createGlobalStyle`
    .dragging {
        opacity: 0.5;
    }

    .cards-hover {
    background-color: rgb(46, 46, 46);
    border-radius: 8px;
}
`

const KanbanCardStyle = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color:rgb(207, 207, 207);
    border-radius: 7px;
    padding: 8px;
    padding-bottom: 0px;
    padding-top: 2px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.1);
`
const Badge = styled.div`
    color:rgb(255, 255, 255);
    background-color:rgb(95, 108, 163);
    width: fit-content;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 7px;
    margin-top: 3px;
`

const BotaoCustomizado = styled.button`
    width: 50%!important;
    background-color: rgb(43, 45, 121); 
    border-radius: 3px;
    padding: 5px 16px;
    color: var(--branco);
    border: solid transparent;
    margin-top: 1em;
    margin-bottom: 0.5em;
    font-weight: 700;
    line-height: 19px;
    margin-right: 2em;
    &:hover {
        background-color: rgb(32, 34, 88);
    }
    &:active{
        border: solid rgb(63, 67, 167);
    }
`
const Close = styled.button`
    top: 101px;
    right: 17px;
    align-self: flex-end;
    position: absolute;
    width: 24px;
    height: 24px;
    background-color: rgba(43, 46, 121, 0.14); 
    border-radius: 3px;
    color: var(--branco);
    border: solid transparent;
    font-weight: 700;
    line-height: 19px;
    padding-right: 14px;
    &:hover {
        background-color: rgba(32, 34, 88, 0.33);
    }
    &:active{
        border: solid rgb(84, 86, 146);
    }
`

const Campo = styled.input`
    color:rgb(19, 19, 19);
    font-size: 15px;
    background:rgba(240, 240, 240, 0.52);
    margin-top: 3px;
    margin-bottom: 0px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgba(0,0,0, 0.25);
    border-radius: 3px;
    border: none;
    width: 100%;
    padding: 10px;
`

interface KanbanNewCardProps {
    onClose: () => void;
    onCardAdded: () => void;
    columnId: number;
}

const KanbanNewCard: React.FC<KanbanNewCardProps> = ({ onClose, onCardAdded, columnId }) => {
    const {register, handleSubmit} = useForm({ mode: 'onSubmit' });
    const ref = useRef<HTMLDivElement>(null);
    const {usuario} = autenticaStore;

    const axiosConfig = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${usuario.token}`,
        },
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            onClose();
        }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    const addCard = (data: any) => {      
        const cardAtualizado = {
            nome: data.novonome,
            statusId: columnId
        }

        try {
            http.post(`card`,cardAtualizado ,axiosConfig)
            .then(resposta => {
                onCardAdded();
                onClose();
            })
            .catch(erro => {            
                alert("ERRO:" + erro)
            })   
        } catch (erro) {
            erro && alert('Erro ao cadastrar card')
        } 
    }

    return (
        <>
        <GlobalStyle />               
        <KanbanCardStyle className="kanban-card" ref={ref}>
            <Badge>
                <span>Novo Card</span>
            </Badge>    
            <Close onClick={onClose}>X</Close> 
            <Campo type="text" placeholder="Nome do CLIENTE" maxLength={100} {...register('novonome',{
                required: 'Campo obrigatório',
                minLength: {
                    value: 3,
                    message: 'Deve ter no mínimo 3 caracteres'
                },
                maxLength: {
                    value: 100,
                    message: 'Deve ter no máximo 100 caracteres'
                }
            })} />      
            <BotaoCustomizado onClick={handleSubmit(addCard)}>Adicionar</BotaoCustomizado>        
        </KanbanCardStyle>
        </> 
    )
}

export default KanbanNewCard;