import styled from "styled-components";
import Container from "../../components/Container";
import ConsolidadoFinanceiro from "../ConsolidadoFinanceiro";
import ConsolidadoVendas from "../ConsolidadoVendas";
import autenticaStore from "../../stores/autentica.store";
import IClifor from "../../types/IClifor";
import http from "../../http";
import * as React from 'react';
import { useState } from "react";
import { set } from "mobx";


const Titulo = styled.h2`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  color: var(--cinza-claro)
`;

export default function Home() {
  const {usuario} = autenticaStore;
  const [nome, setNome] = useState<string | null>('')
  const [papel, setPapel] = useState<string | null>('')
  //const [usuarioID, setUsuarioID] = useState<string | null>('')
  const axiosConfig = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${usuario.token}`,
    },
  };

 

  //setUsuarioID(localStorage.getItem('userID'))

  React.useEffect(() => {
    if (localStorage.getItem('userID') != null ) {
    http.get<IClifor[]>(`clifor/filter?attribute=idusuario&value=${localStorage.getItem('userID')}`, axiosConfig).then(resposta => {
    /* console.log(JSON.stringify(resposta.data[0].nomecomercial))  */     
    setNome(resposta.data[0].nomecomercial)
    })
    .catch(erro => {
        alert(erro)
    });

    http.get(`usuarios/${localStorage.getItem('userID')}`, axiosConfig).then(resposta => {
      //console.log(resposta)
      setPapel(resposta.data.papel)
    })
    .catch(erro => {
        alert(erro)
    });
  }
  }, [])

  return (
    <Container>
        <Titulo style={{marginBottom: '40px'}}>Olá, {nome}</Titulo>
        
        {usuario.login !== 'maria.alice' && <ConsolidadoVendas />}
        {papel === 'ADMIN' && <ConsolidadoFinanceiro />}


    </Container>
  )
}
