import { Outlet, useLocation } from "react-router-dom";
import Cabecalho from "../../components/Cabecalho";
import Rodape from "../../components/Rodape";
import styled from "styled-components";
import banner from "./banner.png"
import wallpaper from "./wallpaper.png"

const ContainerPrincipal = styled.div`
    background-image: url(${wallpaper});
    background-color: #1b1b1b;
    background-size: cover;
    width: 100%;
    height: 100vh;
    overflow: auto;

`

export default function PaginaBase(){
    
    const location = useLocation();
    
    const isCrmPage = location.pathname.includes('/crm');

    return(
        <>
        <ContainerPrincipal>
        {!isCrmPage && <Cabecalho />}
        <main>
            <Outlet />
        </main>
        {!isCrmPage && <Rodape />}
        </ContainerPrincipal>
                
        </>
    )
}