import React, { useState, useEffect, useRef } from 'react';
import SidebarItem from '../SideBarItem';
import styled from 'styled-components';
import autenticaStore from '../../stores/autentica.store';
import http from '../../http';

const Container = styled.div<{ active: boolean }>`
  background-color: #1a1a1a;
  position: fixed;
  height: 100%;
  top: 0px;
  left: ${({ active }) => (active ? '0' : '-100%')};
  width: 300px;
  animation: showSidebar .4s;
  z-index: 1;

  > svg {
    position: fixed;
    color: white;
    width: 30px;
    height: 30px;
    margin-top: 32px;
    margin-left: 32px;
    cursor: pointer;
  }

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 300px;
    }
  }
`;

const Content = styled.div`
  margin-top: 100px;
`;

const LinkEstilizado = styled.a`
  color: var(--cinza-claro);
  font-weight: 700;
  margin: 0px;
  position: absolute;
  margin-bottom: 10px;
  bottom: 3px;
  margin-left: 25px;
`;

interface Props {
  active: boolean;
  setActive: (active: boolean) => void;
}

const Sidebar = ({ active, setActive }: Props) => {
  const { usuario } = autenticaStore;
  const [papel, setPapel] = useState<string | null>('');
  const sidebarRef = useRef<HTMLDivElement>(null);

  const axiosConfig = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${usuario.token}`,
    },
  };

  useEffect(() => {
    http.get(`usuarios/${localStorage.getItem('userID')}`, axiosConfig)
      .then(resposta => {
        setPapel(resposta.data.papel);
      })
      .catch(erro => {
        alert(erro);
      });
  }, [axiosConfig]);

  const handleLogout = () => {
    autenticaStore.logout();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
      setActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={sidebarRef} active={active}>
      <Content>
        <SidebarItem href={"home"} Text="Home" />
        <SidebarItem href={"meuPerfil"} Text="Meu Perfil" />
        <SidebarItem href={"comissao"} Text="Comissões" />
        <SidebarItem href={"crm"} Text="CRM" />
        {usuario.login !== 'maria.alice' && <SidebarItem href={"cliFor"} Text="Cliente e Fornecedor" />}
        {usuario.login !== 'maria.alice' && <SidebarItem href={"vendas"} Text="Vendas" />}
        {usuario.login !== 'maria.alice' && <SidebarItem href={"produtos"} Text="Produtos" />}
        {usuario.login !== 'maria.alice' && <SidebarItem href={"painelNF"} Text="Painel de NF" />}
        {papel === 'ADMIN' && <SidebarItem href={"financeiro"} Text="Financeiro" />}
        {papel === 'ADMIN' && <SidebarItem href={"painelDeControle"} Text="Painel de Controle" color="#835300" />}
        <LinkEstilizado href="/" onClick={handleLogout}>Sair</LinkEstilizado>
      </Content>
    </Container>
  );
};

export default Sidebar;