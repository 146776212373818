import { BrowserRouter, Routes, Route } from "react-router-dom";
import Usuarios from "./pages/Usuarios";
import PaginaBase from "./pages/PaginaBase";
import PaginaInicial from "./pages/PaginaInicial";
import PaginaBaseFormulario from "./pages/PaginaBaseFormulario";
import Login from "./pages/Login";
import Cadastro from "./pages/Cadastro";
import RotaPrivada from "./utils/RotaPrivada";
import Home from "./pages/Home";
import PainelDeControle from "./pages/PaineldeControle";
import MeuPerfil from "./pages/MeuPerfil";
import Clifor from "./pages/Clifor";
import Checkout from "./pages/checkout";
import Vendas from "./pages/Vendas";
import Precificacao from "./pages/Precificacao";
import Financeiro from "./pages/Financeiro";
import CategoriasFinanceiro from "./pages/CategoriasFinanceiro";
import Produtos from "./pages/Produto";
import Taxas from "./pages/Taxas";
import Meta from "./pages/Meta";
import Comissao from "./pages/Comissao";
import PainelNF from "./pages/PainelNF";
import Crm from "./pages/Crm";


function AppRoutes(){
    return(
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<PaginaBase/>}>
                <Route index element={<PaginaInicial />} />
                <Route element={<RotaPrivada />}>
                    <Route path="/usuarios" element={<Usuarios />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/painelDeControle" element={<PainelDeControle />} />
                    <Route path="/meuPerfil" element={<MeuPerfil />} />
                    <Route path="/clifor" element={<Clifor />} />
                    <Route path="/precificacao" element={<Precificacao idProd={''}/>} />
                    <Route path="/financeiro" element={<Financeiro />} />
                    <Route path="/categoriasFinanceiro" element={<CategoriasFinanceiro />} />
                    <Route path="/vendas" element={<Vendas />} />
                    <Route path="/produtos" element={<Produtos />} />
                    <Route path="/taxas" element={<Taxas />} />
                    <Route path="/meta" element={<Meta />} />
                    <Route path="/comissao" element={<Comissao />} />
                    <Route path="/painelNF" element={<PainelNF />} />
                    <Route path="/crm" element={<Crm />} />
                </Route>
            </Route>
            <Route path="/" element={<PaginaBaseFormulario/>}>
                <Route path="/login" element={<Login />} />
                {/* <Route path="/cadastro" element={<Cadastro />} />    */}             
            </Route>    
            <Route path="/checkout" element={<Checkout />} />                
        </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;

/* function AppRoutes(){
    return(
        <BrowserRouter>
        <Routes>
            <Route path="/" element={<PaginaBase/>}>
                <Route index element={<PaginaInicial />} />
                <Route element={<RotaPrivada />}>
                    <Route path="/Usuarios" element={<Usuarios />} />
                </Route>
            </Route>
            <Route path="/" element={<PaginaBaseFormulario/>}>
                <Route path="/login" element={<Login />} />
                <Route path="/cadastro" element={<Cadastro />} />                
            </Route>    
        </Routes>
        </BrowserRouter>
    )
} */